<template>
<div class="group-options__list">
  <ul>
    <li v-for="(opcion, index) in groupData.opciones" :key="index">
      <div
        v-if="opcion.activo"
        :class="Number(opcion.inventario) ? '' : 'group-options__disabled'"
      >
        <checkbox
          v-if="groupData.tipo.id === 'Multiple'"
          :opcion="opcion"
          @onGetOption="getOption"
          @onUpdateQuantity="updateQuantity"
        />
        <radio-button
          v-if="groupData.tipo.id === 'Unica'"
          :opcion="opcion"
          :idGroup="groupData.id"
          @onGetOptionRadio="GetOptionRadio"
          @onUpdateQuantity="updateQuantity"
        />

        <div class="group-options__options">
          <span class="group-options__name">
            {{ opcion.lista }}
            <small v-if="!Number(opcion.inventario)">(Agotado)</small>
          </span>
        </div>
        <div class="group-options__price">
          <span>
            {{ opcion.costoExtra }}
          </span>
        </div>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
import Checkbox from '@/components/shop/groups/checkBox.vue';
import RadioButton from '@/components/shop/groups/radioButton.vue';


export default {
  components: {
    Checkbox,
    RadioButton,
  },
  props: {
    groupData: {
      type: Object,
    },
    selectedOptions: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      group: [],
      groupOptions: [],
      options: [],
      checkedRadio: false,
    };
  },
  methods: {
    GetOptionRadio(opcion) {
      if (!this.groupOptions.opciones.length) {
        this.groupOptions.opciones.push(opcion);
      } else {
        // eslint-disable-next-line
        const index = this.groupOptions.opciones.findIndex(item => item.idOption === opcion.idOption);
        if (index >= 0) {
          const div = document.getElementById(`div-${opcion.idOption}`);
          div.style.display = 'none';
          document.getElementById(opcion.idOption).checked = false;
          this.groupOptions.opciones.splice(index, 1);
        } else {
          this.groupOptions.opciones[0] = opcion;
        }
      }
      this.$emit('onGetGroup', this.groupOptions);
    },
    getOption(opcion) {
      if (opcion.checked) {
        this.groupOptions.opciones.push({ ...opcion });
      } else {
        const index = this.groupOptions.opciones.findIndex(item => item.lista === opcion.lista);
        this.groupOptions.opciones.splice(index, 1);
      }
      this.$emit('onGetGroup', this.groupOptions);
    },
    updateQuantity(opcion) {
      const index = this.groupOptions.opciones.findIndex(item => item.lista === opcion.lista);
      this.groupOptions.opciones[index].cantidad = opcion.cantidad;
      this.$emit('onGetGroup', this.groupOptions);
    },
  },
  created() {
    this.groupOptions = { ...this.groupData };
    this.groupOptions.opciones = [];
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_group-options.scss";
</style>
