<template>
  <div class="group-options__quantity">
    <input
      :disabled="Number(opcion.inventario) ? false : true"
      type="radio"
      :name="idGroup"
      :id="idOption"
      :value="opcion.lista"
      @click="validateChecked"
      :checked="opcion.checked"
      :ref="idOption"
    >
    <br>
    <div
      :name="`div-${idGroup}`"
      :id="`div-${idOption}`"
      v-show="opcion.checked === true">
      <button
        class="group-options__circle"
        @click="addOptionQuantity(false)"
      >
        -
      </button>
      <span>{{ cantidad }}</span>
      <button
        @click="addOptionQuantity()"
        class="group-options__circle"
      >
        +
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    opcion: {
      type: Object,
    },
    idGroup: {
      type: String,
    },
  },
  data() {
    return {
      checkedData: false,
      cantidad: 1,

    };
  },
  computed: {
    idOption() {
      return `${this.idGroup}-${this.opcion.lista}`;
    },
  },
  methods: {
    validateChecked() {
      const divs = document.getElementsByName(`div-${this.idGroup}`);
      divs.forEach((item) => {
        // eslint-disable-next-line
        item.style.display = 'none';
        if (item.id.includes(this.idOption)) {
          // eslint-disable-next-line
          item.style.display = 'block';
        }
      });
      this.opcion.checked = true;
      const data = {
        idOption: this.idOption,
        idGroup: this.idGroup,
        checked: true,
        cantidad: this.cantidad,
        ...this.opcion,
      };
      this.$emit('onGetOptionRadio', data);
    },
    addOptionQuantity(add = true) {
      if (add) {
        this.cantidad += 1;
      } else {
        this.cantidad -= 1;
      }
      this.opcion.cantidad = this.cantidad;
      this.$emit('onUpdateQuantity', this.opcion);
    },
  },
  mounted() {
    if (this.opcion.checked) {
      this.cantidad = this.opcion.cantidad;
      this.validateChecked();
    }
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_group-options.scss";
</style>
