<template>
  <div class="store__header-container">
    <div class="store__header">
      <router-link
        :to="{
          name: 'invite.sellia',
          params: { id: selectedStore.id, client: client },
        }"
        class="store__header-router"
      >
        <div class="store__header-name">
          {{ selectedStore.nombre }}
        </div>
      </router-link>
      <div class="store__header-buttons">
        <router-link
          :to="{
            name: 'invite.sellia',
            params: { id: selectedStore.id, client: client },
          }"
        >
          <hcc-button-icon color="accent" v-tooltip="$t('shop.view-products')">
            <home-icon />
          </hcc-button-icon>
        </router-link>

        <router-link
          :to="{
            name: 'store-cart.admin.sellia',
            params: { id: selectedStore.id, client: client },
          }"
          class="store__header-router"
          v-if="total > 0"
        >
          <hcc-button-icon color="accent" v-tooltip="$t('shop.view-cart')">
            <cart-icon />
            <span class="store__header-total">
              {{ `(${total})` }}
            </span>
          </hcc-button-icon>
        </router-link>
        <hcc-button-icon
          v-else
          unclickable
          disabled
          color="primary"
          v-tooltip="$t('shop.view-cart')"
        >
          <cart-icon />
        </hcc-button-icon>
      </div>
    </div>
  </div>
</template>

<script>
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';

export default {
  components: {
    HccButtonIcon,
  },
  props: {
    selectedStore: {
      type: Object,
    },
    total: {
      type: Number,
    },
    client: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/_store.scss";
</style>
