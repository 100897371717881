<template>
  <div class="group-options">
    <div class="group-options__header">
      <span>{{ $t('shop.product.options.title-list') }}</span>
    </div>
    <div
      class="group-options__list"
      v-for="(group, index) in groupOptionsData" :key="index">
      <span
        v-if="validateGroup(group)"
        class="group-options__title_options">
          {{ group.nombre }}
      </span>
      <options
        @onGetGroup="getGroup"
        :groupData="group"
      />
    </div>
  </div>
</template>

<script>

import Options from '@/components/shop/groups/options.vue';

export default {
  components: {
    Options,
  },
  props: {
    groupOptions: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      group: [],
      radio: [],
      groupOptionsData: [],
      selectedOptionsData: [],
      addRadio: true,
    };
  },
  watch: {
    groupOptions(newValue) {
      this.groupOptionsData = newValue;
    },
  },
  methods: {
    getGroup(group) {
      const index = this.group.findIndex(item => item.id === group.id);
      if (group.opciones.length) {
        if (index < 0) {
          this.group.push(group);
        } else {
          this.group[index] = group;
        }
      } else {
        this.group.splice(index, 1);
      }
      this.$emit('onGetOption', this.group);
    },
    validateGroup(group) {
      return group.opciones.some(option => option.activo);
    },
    getOptions() {
      if (!this.selectedOptions) {
        this.groupOptionsData = { ...this.groupOptions };
      } else {
        this.groupOptionsData = this.groupOptions.map((group) => {
          const index = this.selectedOptions.findIndex(item => item.id === group.id);
          if (index >= 0) {
            const opciones = group.opciones.map((optionGroup) => {
              let cantidadOption = 0;
              const checked = this.selectedOptions[index].opciones.some((opcion) => {
                if (opcion.lista === optionGroup.lista) {
                  cantidadOption = opcion.cantidad;
                  return true;
                }
                return false;
              });
              const optionGroupData = { ...optionGroup };
              optionGroupData.cantidad = cantidadOption;
              return {
                checked,
                ...optionGroupData,
              };
            });
            const groupData = { ...group };
            delete groupData.opciones;
            return {
              opciones,
              ...groupData,
            };
          }
          return group;
        });
      }
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_group-options.scss";
@import "~styles/shared/_inputs.scss";
</style>
