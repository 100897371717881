<template>
  <div class="group-options__quantity">
    <input
      :disabled="Number(opcion.inventario) ? false : true"
      class="group-options__box"
      @change="validateChecked"
      type="checkbox"
      v-model="opcionData"
      :value="opcion"
    />
    <br>
    <div v-if="opcion.checked === true">
      <button
        class="group-options__circle"
        @click="addOptionQuantity(false)"
      >
        -
      </button>
      <span>{{ cantidad }}</span>
      <button
        @click="addOptionQuantity()"
        class="group-options__circle"
      >
        +
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    opcion: {
      type: Object,
    },
  },
  data() {
    return {
      opcionData: [],
      cantidad: 1,
    };
  },
  methods: {
    validateChecked() {
      const checked = this.opcionData.length > 0;
      this.opcion.checked = checked;
      if (!this.opcion.cantidad) {
        this.opcion.cantidad = 1;
      }
      this.$emit('onGetOption', { ...this.opcion });
    },
    addOptionQuantity(add = true) {
      if (add) {
        this.opcion.cantidad += 1;
      } else {
        this.opcion.cantidad -= 1;
      }
      this.cantidad = this.opcion.cantidad;
      this.opcionData[0] = this.opcion;
      this.$emit('onUpdateQuantity', this.opcion);
    },
  },
  mounted() {
    if (this.opcion.checked) {
      this.opcionData.push({ ...this.opcion });
      this.cantidad = this.opcion.cantidad;
      this.validateChecked();
    }
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_group-options.scss";
</style>
